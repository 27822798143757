/**
 *
 * VStack
 *
 */
import * as React from 'react';
import { classNames } from '../../../utils/classNames';

interface Props {
  className?: string;
  children: React.ReactNode;
  type: 'primary' | 'secondary' | 'danger' | 'with-icon' | null;
  icon?: React.ReactNode;
  onClick?(): void;
  disabled?: boolean;
}

export function Button(props: Props) {
  if (props.type === 'primary') {
    return (
      <button
        onClick={props.onClick}
        type="button"
        className={classNames(
            'inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
          props.className,
        )}
        disabled={props.disabled}
      >
        {props.children}
        {props.icon}
      </button>
    );
  }

  if (props.type === 'danger') {
    return (
      <button
        onClick={props.onClick}
        type="button"
        className={classNames(
          'inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
          props.className,
        )}
        disabled={props.disabled}
      >
        {props.children}
        {props.icon}
      </button>
    );
  }

  return (
    <div className={classNames('flex flex-row', props.className)}>
      {props.children}
    </div>
  );
}
