/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import { GlobalStyle } from "../styles/global-styles";

import { NotFoundPage } from "./pages/NotFoundPage";
import { useTranslation } from "react-i18next";
import { Login } from "./pages/Auth";
import useToken from "../hooks/useToken";
import { useApiSlice } from "../api/slice";
import { useDispatch, useSelector } from "react-redux";
import { selectApi } from "../api/slice/selectors";
import { logger } from "../utils/logger";
import { useAuthSlice } from "./modules/profile/slice";
import { AccountManager } from "./pages/AccountManager/Loadable";
import { EditAccount } from "./pages/EditAccount/Loadable";

/**
 * Logger
 */
const log = logger().child({ module: "App" });

export function App() {
  const { i18n } = useTranslation();
  const { clearToken, token, refreshToken } = useToken();

  const { actionApi } = useApiSlice();
  const { actions } = useAuthSlice();
  const api = useSelector(selectApi);
  const dispatch = useDispatch();

  useEffect(() => {
    log.info("dispatch token");
    dispatch(actionApi.loadToken());
  }, [token]);

  useEffect(() => {
    if (refreshToken) {
      dispatch(actions.setRefreshTokenData(refreshToken));
      dispatch(actions.loadRefreshToken());
    }
  }, [refreshToken]);

  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - Dashboard Account Manager"
        defaultTitle="Dashboard Account Manager"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="Rekosistem" />
      </Helmet>

      <Switch>
        {/* navigation reverted to this file */}
        {/*<MainRouter />*/}
        <Route
          exact
          path={process.env.PUBLIC_URL + "/login"}
          component={() => (token ? <Redirect to="/" /> : <Login />)}
          // component={() => <Login />}
        />
        <Route
          exact
          path={process.env.PUBLIC_URL + "/logout"}
          component={() => {
            clearToken();
            window.location.replace("/login");
            return <></>;
          }}
        />
        {token ? (
          <Switch>
            <Route
              exact
              path={process.env.PUBLIC_URL + "/"}
              component={() => <AccountManager title={"Account Manager"} />}
            />

            {/* Account Manager Route */}
            <Route
              exact
              path={process.env.PUBLIC_URL + "/account-manager"}
              component={() => <AccountManager title={"Account Manager"} />}
            />

            {/* Edit and Register Account Route */}
            <Route
              exact
              path={process.env.PUBLIC_URL + "/register"}
              component={() => (
                <EditAccount title={"Register Account"} edit={false} />
              )}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + "/edit-account/:id"}
              component={() => (
                <EditAccount title={"Account Detail"} edit={true} />
              )}
            />
            <Route path={"*"} component={() => <NotFoundPage />} />
          </Switch>
        ) : (
          <Redirect to="/login" />
        )}
      </Switch>
      <GlobalStyle />
    </BrowserRouter>
  );
}
