import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './index';

const selectSlice = (state: RootState) => state.auth || initialState;

export const selectLoginData = createSelector(
  [selectSlice],
  state => state.loginData,
);
export const selectSelectedUserId = createSelector(
    [selectSlice],
    state => state.selectedUserId,
);
export const selectUserData = createSelector(
  [selectSlice],
  state => state.userData,
);
export const selectAccessToken = createSelector(
  [selectSlice],
  state => state.userData.accessToken,
);
export const selectRefreshToken = createSelector(
  [selectSlice],
  state => state.refreshToken,
);

export const selectErrors = createSelector([selectSlice], state => state.errors);

export const selectMessage = createSelector(
  [selectSlice],
  state => state.message,
);

export const selectProfileLoading = createSelector(
  [selectSlice],
  state => state.loading,
);

export const selectLoginSuccess = createSelector(
    [selectSlice],
    state => state.loginSuccess,
);
