/**
 *
 * Asynchronously loads the component for Banner
 *
 */

import { lazyLoad } from 'utils/loadable';

export const AccountManager = lazyLoad(
    () => import('./index'),
    module => module.AccountManager,
);
