import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthSlice } from "../../modules/profile/slice";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  selectAccessToken,
  selectErrors,
  selectProfileLoading,
} from "../../modules/profile/slice/selectors";
import { Constants } from "../../../constants";
import { TextInput } from "../../components/TextInput";
import { Button } from "../../components/Button";
import { Spinner } from "../../components/Spinner";
import { LoadingOverlay } from "../../components/LoadingOverlay";

export function Login() {
  const { actions } = useAuthSlice();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const signIn = async () => {
    if (username !== "" && password !== "") {
      await dispatch(actions.setLoginData({ username, password }));
      await dispatch(actions.loadUserData());
    }
  };

  const accessToken = useSelector(selectAccessToken);
  const errors = useSelector(selectErrors);
  const loading = useSelector(selectProfileLoading);
  const history = useHistory();

  useEffect(() => {
    if (accessToken) history.go(0);
  }, [history, accessToken]);

  // @ts-ignore
  return (
    <>
      <div className="flex h-screen">
        <div className="m-auto w-full max-w-sm lg:w-96">
          <div>
            <div className={"flex items-center"}>
              <h2 className="mt-2 text-3xl font-extrabold text-dark">
                Log in to {Constants.APP_NAME}
              </h2>
            </div>
          </div>

          <div className="mt-8">
            <div className="mt-6">
              <form action="#" method="POST" className="space-y-6">
                <TextInput
                  label={"Enter email address"}
                  type={"top-label"}
                  placeholder={"Your email address"}
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                  value={username}
                  isError={!!errors}
                  errorMessage={""}
                />

                <TextInput
                  label={"Enter password"}
                  type={"top-label"}
                  placeholder={"Your password"}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  value={password}
                  isError={!!errors}
                  errorMessage={""}
                  formType={"password"}
                  className="mb-2"
                />
                {errors ? <span className="text-red-600">{errors}</span> : null}
                <div>
                  <Button
                    className={"mt-8 w-full flex justify-center"}
                    type={"primary"}
                    onClick={signIn}
                    disabled={loading}
                  >
                    Log in
                    {loading ? (
                      <Spinner size={"small"} className={"ml-2"} />
                    ) : null}
                  </Button>
                </div>
              </form>
            </div>
            <div className="flex flex-row justify-center mt-12">
              <div>
                <a href="https://rekosistem.com/">
                  <h2 className="text-sm text-dark">rekosistem.com</h2>
                </a>
              </div>
              <div className="mx-6">
                <a href="#">
                  <h2 className="text-sm text-dark">Contact us</h2>
                </a>
              </div>
              <div>
                <h2 className="text-sm text-dark">
                  © {new Date().getFullYear()}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <LoadingOverlay isActive={loading} />
      </div>
    </>
  );
}
