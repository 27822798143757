/**
 *
 * Asynchronously loads the component for Banner
 *
 */

import { lazyLoad } from 'utils/loadable';

export const EditAccount = lazyLoad(
    () => import('./edit-account'),
    module => module.EditAccount,
);
