// @ts-nocheck
import { GeneralApiProblem } from "./api-problem";
import { Pagination } from "../types/Pagination";
import { AllAccountType } from "../types/Account";

/**
 * Storage Key
 */
export const ACCESS_TOKEN_KEY = "accessToken";
export const REFRESH_TOKEN_KEY = "refreshToken";

const AUTH = "/auth";
const ADMIN = "/admin";

/**
 * API Definition
 */
const AUTH_PORT = process.env.REACT_APP_AUTH + AUTH;
const ADMIN_PORT = process.env.REACT_APP_AUTH + ADMIN;
const DROP_STATION_PORT = process.env.REACT_APP_DROP_STATION;

// Auth & User
export const LOGIN = AUTH_PORT + "/login-admin";
export const REFRESH_TOKEN_ADMIN = AUTH_PORT + "/refresh-token-admin";
export const ALL_ACCOUNT = ADMIN_PORT + "/user";
export const SEARCH_ACCOUNT = ADMIN_PORT + "/user";
export const DETAIL_ACCOUNT = ADMIN_PORT + "/user/";
export const SUB_ACCOUNT = ADMIN_PORT + "/user/sub-account-by-code";
export const REGISTER_ACCOUNT = ADMIN_PORT + "/user";
export const EDIT_ACCOUNT = ADMIN_PORT + "/user/";
export const DELETE_ACCOUNT = ADMIN_PORT + "/user/";

export interface GeneralDataResponse {
  data: any;
  errors: any;
  message: string | any;
  status: boolean | any;
}

export interface AllAccountResponse extends GeneralDataResponse {
  data: {
    pagination: Pagination;
    data: AllAccountType[];
  };
}

export enum ApiResponseKind {
  OK = "ok",
}

export enum ApiMethod {
  GET = "get",
  POST = "post",
  PUT = "put",
  DELETE = "delete",
}

export const MULTIPART_HEADER = {
  "Content-Type": "multipart/form-data",
};

export enum ResponseType {
  BLOB = "blob",
}

export type GeneralApiResult =
  | { kind: ApiResponseKind.OK; data: GeneralDataResponse }
  | GeneralApiProblem;

export type LoginResult =
  | { kind: ApiResponseKind; data: GeneralDataResponse }
  | GeneralApiProblem;

export type AllAccountResult =
  | { kind: ApiResponseKind; data: AllAccountResponse }
  | GeneralApiProblem;

export type GeneralSubmitResult =
  | { kind: ApiResponseKind; data: GeneralDataResponse }
  | GeneralApiProblem;
