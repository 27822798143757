import { logger } from "../utils/logger";

/**
 * Logger
 */
const log = logger().child({ module: "ApiMonitor" });

export function responseMonitor(response: any) {
  log.info("🕵️ Api Response Monitor 🕵️");
  log.debug("Response :", response);

  const { status } = response;

  if (status === 401) {
    log.info("Token Expired ❌ " + response.data.message);
    window.location.assign("/");
  }
}
