import { ApiResponse, ApisauceInstance, create } from "apisauce";
import { getGeneralApiProblem } from "./api-problem";
import { ApiConfig, DEFAULT_API_CONFIG, UCO_API_CONFIG } from "./api-config";
import { logger } from "utils/logger";
import * as Types from "./api.types";
import { ApiMethod, ApiResponseKind, MULTIPART_HEADER } from "./api.types";
import { responseMonitor } from "./api-monitor";
import { ResponseType } from "axios";

/**
 * Logger
 */
const log = logger().child({ module: "MainApi" });

/**
 * Manages all requests to the API.
 */
export class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  // @ts-ignore
  apisauce: ApisauceInstance;
  // @ts-ignore
  apisauceUco: ApisauceInstance;

  /**
   * Configurable options.
   */
  config: ApiConfig;
  configUco: ApiConfig;

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG || UCO_API_CONFIG) {
    this.config = DEFAULT_API_CONFIG;
    this.configUco = UCO_API_CONFIG;
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup() {
    log.info("Setup Api");
    // construct the apisauce instance
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: "application/json",
      },
    });
    this.apisauce.addMonitor(responseMonitor);
  }

  setupUco() {
    log.info("Setup Api Uco");
    // construct the apisauce UCO instance
    this.apisauceUco = create({
      baseURL: this.configUco.url,
      timeout: this.configUco.timeout,
      headers: {
        Accept: "application/json",
      },
    });
    this.apisauceUco.addMonitor(responseMonitor);
  }

  setToken(token: string) {
    log.debug("TOKEN set 🚀 : " + token);
    this.apisauce.setHeader("Authorization", `Bearer ${token}`);
    this.apisauceUco.setHeader("Authorization", `Bearer ${token}`);
  }

  removeToken() {
    log.debug("TOKEN removed 🔥");
    this.apisauce.deleteHeader("Authorization");
    this.apisauceUco.deleteHeader("Authorization");
  }

  // General API Call
  async generalApiCall(
    method: ApiMethod,
    url: string,
    isMultipart: boolean,
    responseType?: ResponseType,
    timeout?: number,
    ...args
  ): Promise<Types.GeneralApiResult> {
    // make the api call
    let response: ApiResponse<any>;

    const config = {
      responseType: responseType,
      timeout: timeout,
      headers: isMultipart ? MULTIPART_HEADER : undefined,
    };

    // check api call method
    if (method === ApiMethod.GET)
      response = await this.apisauce.get(url, ...args, config);
    else if (method === ApiMethod.POST)
      response = await this.apisauce.post(url, ...args, config);
    else if (method === ApiMethod.PUT)
      response = await this.apisauce.put(url, ...args, config);
    else response = await this.apisauce.delete(url, ...args, config);

    // return if there is a problem in api call
    if (!response.ok) return getGeneralApiProblem?.(response);

    // return if all ok
    return { kind: ApiResponseKind.OK, data: response.data };
  }
}
