import React, { createContext, ReactNode, useContext } from 'react';
import { Api } from './api';
import { useApiSlice } from './slice';
import { useSelector } from 'react-redux';
import { selectAllApi, selectApi } from './slice/selectors';
import { ApiState } from './slice/types';

export const ApiContext = createContext<ApiState>({} as ApiState);

export const useApis = (): ApiState => useContext(ApiContext);

export type StoreComponentProps = {
  children: ReactNode;
};

export const ApiProvider: React.FC<StoreComponentProps> = ({ children }) => {
  useApiSlice();
  const api = useSelector(selectAllApi);

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};
