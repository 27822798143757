import React, { ChangeEvent } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { classNames } from '../../../utils/classNames';

export type SelectDataItem = { id: number; name: string };

interface Props {
  label?: string;
  value?: string | number;
  onChange?(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
  type?: 'multiline' | 'with-addon' | 'top-label' | 'no-label' | 'both-addon';
  addonLabel?: string;
  trailingLabel?: string;
  description?: string;
  topLine?: boolean;
  disabled?: boolean;
  placeholder?: string;
  isError?: boolean | '' | undefined;
  errorMessage?: string;
  formType?: string;
  id?: string;
  required?: boolean;
  min?: string | number | undefined;
  max?: string | number | undefined;
  className?: string;
}

export const TextInput = ({
  label,
  value,
  onChange = () => null,
  type,
  min,
  max,
  placeholder = '',
  addonLabel,
  description,
  isError,
  disabled = false,
  required = false,
  topLine = true,
  errorMessage = 'Your password must be less than 4 characters.',
  formType = 'text',
  id,
  className = '',
  trailingLabel = '',
}: Props) => {
  if (type === 'top-label') {
    const ERROR_FORM =
      'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md';

    const BASE_FORM =
      'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md';

    return (
      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <input
            type={formType}
            min={min}
            max={max}
            disabled={disabled}
            required={required}
            className={classNames(isError ? ERROR_FORM : BASE_FORM, className)}
            placeholder={placeholder}
            value={value}
            onChange={e => onChange(e)}
            id={id}
          />
          {isError === true ? (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
              {errorMessage}
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  if (type === 'no-label') {
    const ERROR_FORM =
      'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md';

    const BASE_FORM =
      'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md';

    return (
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          type={formType}
          min={min}
          max={max}
          disabled={disabled}
          required={required}
          className={classNames(isError ? ERROR_FORM : BASE_FORM, className)}
          placeholder={placeholder}
          value={value}
          onChange={e => onChange(e)}
          id={id}
        />
        {isError === true ? (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
            {errorMessage}
          </div>
        ) : null}
      </div>
    );
  }

  if (type === 'multiline') {
    const ERROR_FORM =
      'max-w-lg shadow-sm block w-full text-red-900 border-red-300 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md';

    const BASE_FORM =
      'max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md';

    return (
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="about"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          {label}
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <textarea
            id={id}
            rows={3}
            required={required}
            disabled={disabled}
            className={classNames(isError ? ERROR_FORM : BASE_FORM, className)}
            value={value}
            onChange={e => onChange(e)}
          />
          {description && (
            <p className="mt-2 text-sm text-gray-500">{description}</p>
          )}
          {isError === true ? (
            <div className="mt-2 inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
              <p className="ml-2 text-sm text-red-600" id="email-error">
                {errorMessage}
              </p>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  if (type === 'with-addon') {
    return (
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="username"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          {label}
        </label>
        <div className="mt-1 rounded-md shadow-sm sm:mt-0 sm:col-span-2">
          <div className="max-w-lg flex rounded-md shadow-sm">
            <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
              {addonLabel}
            </span>
            <input
              type={formType}
              min={min}
              max={max}
              disabled={disabled}
              id={id}
              value={value}
              required={required}
              className={classNames(
                'flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300',
                className,
              )}
              onChange={e => onChange(e)}
            />
          </div>
        </div>
      </div>
    );
  }

  if (type === 'both-addon') {
    return (
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="username"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          {label}
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <div className="max-w-lg flex rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm">{addonLabel}</span>
            </div>
            <input
              type={formType}
              min={min}
              max={max}
              disabled={disabled}
              id={id}
              value={value}
              required={required}
              className={classNames(
                className,
                addonLabel ? 'pl-7' : null,
                'focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md',
              )}
              onChange={e => onChange(e)}
            />
            <div className="absolute inset-y-0 right-0 pr-5 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm" id="price-currency">
                {trailingLabel}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const ERROR_FORM =
    'flex-1 block w-full border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md';

  const BASE_FORM =
    'flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-300 rounded-md';

  return (
    <div
      className={
        topLine
          ? 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'
          : 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start'
      }
    >
      <label
        htmlFor="username"
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        {label}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <div className="max-w-lg flex rounded-md shadow-sm">
          <input
            type={formType}
            min={min}
            max={max}
            disabled={disabled}
            value={value}
            required={required}
            placeholder={placeholder}
            className={classNames(isError ? ERROR_FORM : BASE_FORM, className)}
            onChange={e => onChange(e)}
            id={id}
          />
        </div>
        {isError === true ? (
          <div className="mt-2 inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
            <p className="ml-2 text-sm text-red-600" id="email-error">
              {errorMessage}
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
};
