import { call, put, select, takeLatest } from "redux-saga/effects";
import { profileActions as actions } from "./index";
import { LoginData } from "./types";
import { selectLoginData, selectRefreshToken } from "./selectors";
import { selectApi } from "../../../../api/slice/selectors";
import { apiActions } from "../../../../api/slice";
import { logger } from "../../../../utils/logger";
import {
  ApiMethod,
  ApiResponseKind,
  LOGIN,
  REFRESH_TOKEN_ADMIN,
} from "../../../../api/api.types";

/**
 * Logger
 */
const log = logger().child({ module: "ProfileSaga" });

export function* loginFlow() {
  const body: LoginData = yield select(selectLoginData);
  const api = yield select(selectApi);

  try {
    const response = yield call(
      [api, "generalApiCall"],
      ApiMethod.POST,
      LOGIN,
      false,
      undefined,
      undefined,
      {
        username: body.username,
        password: body.password,
      }
    );

    if (response.kind === ApiResponseKind.OK) {
      const data = response.data;
      if (data?.status) {
        yield put(actions.setUserData(data?.data));
        yield put(
          apiActions.setApiToken({
            accessToken: data.data?.accessToken,
            refreshToken: data.data?.refreshToken,
          })
        );
        yield put(
          apiActions.saveToken({
            accessToken: data.data?.accessToken,
            refreshToken: data.data?.refreshToken,
          })
        );
      } else {
        yield put(actions.loginFailed(data?.errors));
      }
    } else {
      yield put(actions.loginFailed(response?.response));
    }
  } catch (err: any) {
    log.error("Error login", err);
  }
}

export function* refreshTokenFlow() {
  const refreshToken: string = yield select(selectRefreshToken);
  const api = yield select(selectApi);

  try {
    const response = yield call(
      [api, "generalApiCall"],
      ApiMethod.POST,
      REFRESH_TOKEN_ADMIN,
      false,
      undefined,
      undefined,
      {
        refreshToken: refreshToken,
      }
    );

    if (response.kind === ApiResponseKind.OK) {
      const data = response?.data?.data;
      yield put(
        apiActions.setApiToken({
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
        })
      );
      yield put(
        apiActions.saveToken({
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
        })
      );
    } else {
      yield put(actions.loadRefreshTokenFailed(response?.response));
      window.location.replace("/logout");
    }
  } catch (err: any) {
    log.error("Error load refresh token", err);
  }
}

export function* authSaga() {
  yield takeLatest(actions.loadRefreshToken.type, refreshTokenFlow);
  yield takeLatest(actions.loadUserData.type, loginFlow);
}
